.wrapper {
	background-color: black;
}

.initialLanding {
	--headerheight: 75px;
	/* background-color: black; */
	height: calc(99vh - var(--headerheight));
	width: 100%;
	background-image: url("../assets/LandingTrans.png");
	background-repeat: no-repeat;
    background-size: cover;	
}

.initialLanding .landingtextcontent {
	display: flex;
	align-items: center;
	height: 100%;
	width: 100%;
}

.landingheaderimg {
	/* background-image: url("../assets/LandingImage-removebg-preview.png"); */
	height: 100vh;
	/* width: 100vw; */
	color: red;
	background-repeat: no-repeat;
	background-size: fit;
	/* background-color: red; */
}
.row {
    margin-left: 0px;
    margin-right: 0px;
}
.titlewrapper {
	display: flex;
	flex-direction: column;
	/* align-items: center; */
	height: 100%;
	justify-content: center;
	/* margin-right: -690px; */
}
.fadein {
	animation: fadein2 2s;
	animation-fill-mode: forwards;
}
.fadeinsecond {
	
	animation: fadein2 2s 2s;
	opacity: 0;
	animation-fill-mode: forwards;
	/* animation-delay: 2s; */
}
.fadeingoup {
	animation: fadein1 2.5s;
	animation-fill-mode: forwards;
}


@keyframes fadein1 {
	0% { opacity: 0}
	50% { opacity: 1}
	100% { transform: translateY(-50px)}
}
@keyframes fadein2 {
	from { opacity: 0 }
	to { opacity: 1; transform: translateY();}
}
.about {
	min-height: 50vh;
	width: 100%;;
	/* margin-top: 100px; */
	/* margin-left: 20px; */
	/* margin-right: 20px; */
}
.innerAbout {
	width: 90%;
	margin: auto;
}
.about .inneraboutwrapper {
	display: flex;
	height: 100%;

}

.products {
	margin-top: 40px;
}
.solution {
	/* margin-top: 100px; */
	margin-bottom: 100px;
	
}
.solution .wrapper {
	background-color: rgb(23 23 23/1);
	width: 80%;
	height: auto;
	border-radius: 1.5rem;
	padding: clamp(0.2rem,6vw, 2.5rem);
	gap: 3rem;
	margin-left: auto;
	margin-right: auto;
	box-shadow:  -3px 3px 10px 2px rgba(255, 255, 255, 0.3);
}

.solution .wrapper:hover {
	box-shadow:  0 0 20px 2px white;
}
.solution .heading {
	/* line-height: 1.25rem; */

	font-weight: 300;	
	background: var(--grad);
	background: linear-gradient(90deg,#d1aad7,#c88bc4 25.17%,#7b8fdd 50%,#86bff2 73.09%,#bbdef2);
    -webkit-background-clip: text;
	background-clip: text;
    -webkit-text-fill-color: transparent;
}

.solution .description {
	font-size: 1.125rem;
    line-height: 1.65;
	color: white;
	margin-top: 30px;
}
.solution .picwrapper {
	width: 100%;
	height: 100%;
	/* background: linear-gradient(45deg,#d1aad7,#c88bc4 25.17%,#7b8fdd 50%,#86bff2 73.09%,#bbdef2); */
	border-radius: 1.5rem;
	/* background-color: wheat; */
	background-image: var(--grad-diag);
	padding: clamp(2px, 1vw, 20px)
}

@media (max-width: 991px) {
	.solution .picwrapperwrapper {
		margin-bottom: clamp(5px, 10vw, 50px)
	}
}

.seperator {
	width: 100%; 
	height: 1px;
	background-color: rgb(82 82 82);
	margin: auto;
	margin-top: 20px;
	margin-bottom: 20px;	
}
.solution .inwrapper {
	
	width: 100%;
	height: 100%;
	
}
.solution .img {
	width: 100%;
	height: 100%;
	border-radius: 1.5rem;
	object-fit: cover;
	/* width: 500px; */
	/* height: 300px; */
	
}

.visitLink {
	border-radius: 9999px;
	/* border: 2px solid; */
	/* border-image: linear-gradient(45deg,#d1aad7,#c88bc4 25%,#7b8fdd 50%,#86bff2 74.87%,#bbdef2 99.88%) 1; */

	
	border: double 1px transparent;
	border-radius: 9999px;
	background-image: linear-gradient(black, black), 
					linear-gradient(45deg,#d1aad7,#c88bc4 25%,#7b8fdd 50%,#86bff2 74.87%,#bbdef2 99.88%);
	background-origin: border-box;
	background-clip: content-box, border-box;
	display: inline-block;
	margin: 5px 10px 10px 5px;
	
}

.visitLink .link {
	color: white;
	text-decoration: none;
	/* background-color: black; */
	cursor: pointer;
	height: 100%;
	width: 100%;
	display: inline-block;
	border-radius: 9999px;

}

.visitLink:hover {
	background-image: linear-gradient(white, white);
	transition: background 2s ease-in-out;
}
.visitLink .link:hover {
	color: black;
	/* transition: var(--transition) */

}

.visitLink .text {
	/* width: 100%; */
	/* padding-left: 1.5rem; */
	/* padding-right: 1.5rem; */
	text-align: center;
	font-size: clamp(.75rem, 3vw, 1rem);
	font-weight: 500;
	margin: clamp(3px, 3vw, 10px);
	margin-left: clamp(3px, 3vw, 20px);
	margin-right: clamp(3px, 3vw, 20px);
	display: inline-block;
	
}
.landingOpenDesc {
	width: 60%;
	text-align: center;
	margin: auto;
}

.newspreview {
	/* height: 300px; */
	/* width: 300px; */
	background-color: black;
	margin: 10px;
	margin-bottom: 0px;
	margin-top: 0px;

	border-top: 1px solid white;
	border-bottom: 1px solid white;
}
.newswrapper {
	margin-top: 50px;
	margin-bottom: 50px;
}
.newsheading {
	margin: 35px;
	color: white;
	font-size: 2rem;
	font-weight: 800;
	margin-left: 10px;
}

.newspreview .textwrapper {
	width: 70%;
}
.newswrapper .imgwrapper {
	background-color: var(--dark-gray);
	width: 250px;
	height: 250px;
	border-radius: 1rem;
}

@media (max-width: 767px) {
	.newswrapper .hideonsmall {
		display: none;
	}
	.newspreview .textwrapper {
		width: 100%;
	}
}

.newswrapper .imgwrappercenter {
	display: flex;
	justify-content: center;
	align-items: center;
}

.newsdescriptor {
	color: var(--slate);
	font-size: .9rem;
	font-weight: 800;
	margin-left:0px;
	margin-right: 30px;
	margin-top: 10px;
	margin-bottom: 10px;
	text-transform: uppercase;
	font-style: monospace;
	display: inline;

}
.newspreview .topiccolor1 {
	color: var(--magenta)
}
.newspreview .topiccolor2 {
	color: var(--purple)
}

.newspreview:hover {
	cursor: pointer;
	background: rgba(30,30,30, 0.8);
	backdrop-filter: blur(5px);
	background-blend-mode: overlay;
	
}


.research, .news, .org, .recog {
	margin-bottom: 100px;
}

.tempfooter {
	background-color: black;
	height: 20vh;
}

.boxshadow1 {
	box-shadow: 2px 2px 2px 1px rgba(200, 200, 200, 0.5);
}


.profile .img {
	width: 100%;
	/* height: 100%; */
	

}

.profile  {
	margin-left: 20px;	
	margin-right: 20px;
	margin-bottom: 20px;
	margin-top: 20px;
	display: inline-block;
	height: 100%;
	/* min-height: 400px; */
  	/* background: var(--grad); */
}
.profile:hover {
	box-shadow: inset 0 0 50px 2px black,
	inset 0 0 3px 2px black;
	background: var(--grad2);
	transition: var(--transition);

}

.profile .wrapper {
	border: 2px solid lightslategrey;
	margin-top: 10px;
	margin-bottom: 10px;
	/* min-height: 400px; */
}
.profile:hover .wrapper {
	border: 2px solid;
	border-image: var(--grad2);
	border-image-slice: 30;
	transition: var(--transition);
}

.profile .name {
	color: white;
	margin: 10px;
	margin-top: 20px;
	margin-left: 20px;
	font-size: clamp(0%, 2.3vw, 1rem);
}

.profile .title {
	font-family: monospace, monospace;
	color: var(--slate);
	font-size: clamp(0%, 2vw, .8rem);
	margin: 10px;
	margin-top: 0px;
	margin-left: 20px;
	margin-bottom: 3px;
	text-transform: uppercase;
	overflow-wrap: break-word;
	white-space: normal;
	
}

.profile .dept {
	font-style: italic;
	font-family: monospace, monospace;
	color: var(--slate);
	margin: 10px;
	margin-top: 0px;
	margin-left: 20px;
	font-size: clamp(0%, 2vw, .8rem);
	text-transform: uppercase;
	overflow-wrap: break-word;
	white-space: normal;
	
}
.peoplecarouselwrapper {
	position: relative;
	overflow: hidden;
	/* height: 500px; */
}
.peoplecarousel {
	/* position: absolute; */
	top: 0px;
	left: 0px;
	/* overflow: hidden; */
	white-space: nowrap;
	animation: carouselmove 50s linear infinite;
}

@keyframes carouselmove {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(-166%, 0);
	}
}

.collegeimages {
	background-color: #ffffff;
	border-radius: 1rem;
	margin: 10px;
	height: 110px;
	display: flex;
	justify-content: center;
	align-items: center;
	
}
.collegeimages .collegeimg {
	overflow: hidden;
	/* height: 100%; */
	width: 100%;
	max-height: 100%;
	object-fit: contain;
	/* height: 100%;
	max-width: 100%;
	padding: 10px;
	object-fit: cover;
	background-repeat: no-repeat;
	background-position: center; */
}

.awardimages {
	background-color: #ffffff;
	border-radius: 1rem;
	margin: 10px;
	height: 210px;
	width: 300px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.awardimages .awardimg {
	width: 100%;
	max-height: 100%;
	object-fit: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.collegeimages:hover, .awardimages:hover {
	transition: var(--transition);
	box-shadow:  0 0 50px 2px white,
	 0 0 3px 2px white;
	
}
.landingimg {
	overflow: hidden;
	width: 100%;
	object-fit: cover;
}
.landingrow1 {
	background: url('../assets/landing4.jpeg') rgba(0, 0, 0, 0.4);
	padding-top: 100px;
	padding-bottom: 100px;
	backdrop-filter: blur(5px);
	background-blend-mode: overlay;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 50vh;
}

.landingrow2 {
	background: url('../assets/landing2.jpeg') rgba(0, 0, 0, 0.4);
	padding-top: 100px;
	padding-bottom: 100px;
	backdrop-filter: blur(5px);
	background-blend-mode: overlay;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 50vh;
}

.paperImg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	background-repeat: no-repeat;
	background-position: center;
}


.paper {
	/* widseperatorth: 200px; */
	/* height: 400px; */
	background-color: black;
	margin: 20px;
	
	/* border:  solid 3px; */
	/* border-image: var(--grad); */
	/* border-image-slice: 30;	 */
}

.paper:hover  {
	box-shadow: inset 0 0 50px 2px black,
	inset 0 0 3px 2px black;
	background: var(--grad2);
	/* transition: var(--transition); */

}
/* 
.profile .wrapper {
	border: 2px solid lightslategrey;
	margin-top: 10px;
	margin-bottom: 10px;
} */
/* .paper:hover{
	border: 2px solid;
	border-image: var(--grad2);
	border-image-slice: 30;
	transition: var(--transition);
} */


.paperwrapper {
	margin-top: 10px;
	margin-bottom: 10px;
	box-shadow:  0 0 20px 2px white;
}
.paper:hover .paperwrapper {
	box-shadow: none;
}

#tsparticles {
	/* position: absolute; */
	width: 100%;
	height: 100vh;
	/* height: 100%; */
	/* background-color: #323031; */
	background-image: url("");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 50%;
  }
  
.newsborderrad{
	border-radius: 1rem;
}