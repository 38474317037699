
  
  .wordchangewrapper {
	height: 4rem;
	margin: auto;
	overflow: hidden;
	display: inline-block;
	
	.bannerword {
		font-size: 3.5rem;
	}

	@media(max-width: 715px) {
		.bannerword {
			font-size: clamp(7vw, 7vw, 3.5rem);
		}
	}


	ul {
	  padding: 0;
	  animation: scrollUp 25s infinite;
	  li {
		display: flex;
		align-items: center;
		
		height: 4rem;
		list-style: none; 
	
	  }
	}
  }

  
  
  // Variables
  $item-count: 6;
  
  @keyframes scrollUp {
	@for $i from 1 through ($item-count - 1) {
	  #{($i * 20) - 15%}, #{$i * 20%} {
		transform: translateY((-100% / $item-count) * $i);
	  }
	}
  }

  .whitetext {
	color: white !important;
  }


// .wordchange {
// 	display: inline-block;
// 	position: relative;
// }
// .wordchange span {
// 	--up: -100px;
// 	--mid: -25px;
// 	--down: 50px;
// 	/* display: inline-block;
// 	position: absolute;
// 	top: var(--up);
// 	transform: rotateX(-90deg);
// 	opacity: 0;
// 	text-shadow: 0px 5px 5px rgba(0,0,0,.25);
// 	color: white;
// 	animation-timing-function: ease; */
// }

// .wordchange span:nth-child(1) {
// 	animation: rollDown1 10s forwards infinite;
// }
// .wordchange span:nth-child(2) {
// 	animation: rollDown2 10s forwards infinite;
// }
// .wordchange span:nth-child(3) {
// 	animation: rollDown3 10s forwards infinite;
// }
// .wordchange span:nth-child(4) {
// 	animation: rollDown4 10s forwards infinite;
// }
// .wordchange span:nth-child(5) {
// 	animation: rollDown5 10s forwards infinite;
// }





// @keyframes rollDown1 {
// 	0% {
// 		top: var(--up);
// 		transform: rotateX(-90deg);

// 	}
// 	7% {
// 		top: var(--mid);;
// 		transform: rotateX(0deg);
// 		opacity: 1;
// 	}
// 	14% {
// 		top: var(--mid);;
// 		transform: rotateX(0deg);
// 		opacity: 1;
// 	}
// 	20% {
// 		top: var(--down);
// 		transform: rotateX(30deg);
// 		opacity: 0;
// 	}
// }
// @keyframes rollDown2 {
// 	20% {
// 		top: var(--up);
// 		transform: rotateX(-90deg);

// 	}
// 	27% {
// 		top: var(--mid);;
// 		transform: rotateX(0deg);
// 		opacity: 1;
// 	}
// 	34% {
// 		top: var(--mid);;
// 		transform: rotateX(0deg);
// 		opacity: 1;
// 	}
// 	40% {
// 		top: var(--down);
// 		transform: rotateX(30deg);
// 		opacity: 0;
// 	}
// }

// @keyframes rollDown3 {
// 	40% {
// 		top: var(--up);
// 		transform: rotateX(-90deg);

// 	}
// 	47% {
// 		top: -75px;
// 		top: var(--mid);;
// 		transform: rotateX(0deg);
// 		opacity: 1;
// 	}
// 	54% {
// 		top: var(--mid);;
// 		transform: rotateX(0deg);
// 		opacity: 1;
// 	}
// 	60% {
// 		top: var(--down);
// 		transform: rotateX(30deg);
// 		opacity: 0;
// 	}
// }

// @keyframes rollDown4 {
// 	60% {
// 		top: var(--up);
// 		transform: rotateX(-90deg);

// 	}
// 	67% {
// 		top: -75px;
// 		top: var(--mid);;
// 		transform: rotateX(0deg);
// 		opacity: 1;
// 	}
// 	74% {
// 		top: var(--mid);;
// 		transform: rotateX(0deg);
// 		opacity: 1;
// 	}
// 	80% {
// 		top: var(--down);
// 		transform: rotateX(30deg);
// 		opacity: 0;
// 	}
// }
// @keyframes rollDown5 {
// 	80% {
// 		top: var(--up);
// 		transform: rotateX(-90deg);

// 	}
// 	87% {
// 		top: -75px;
// 		top: var(--mid);;
// 		transform: rotateX(0deg);
// 		opacity: 1;
// 	}
// 	94% {
// 		top: var(--mid);;
// 		transform: rotateX(0deg);
// 		opacity: 1;
// 	}
// 	100% {
// 		top: var(--down);
// 		transform: rotateX(30deg);
// 		opacity: 0;
// 	}
// }

