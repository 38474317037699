:root {
	--dark-gray: #21272a;
	--frost-black: rgb(23 23 23/1);
	--light-gray: #697077;
	--white: #FFFFFF;
	--purple: #8a3ffc;
	--magenta: #D02670;
	--grad: linear-gradient(90deg,#d1aad7,#c88bc4 25.17%,#7b8fdd 50%,#86bff2 73.09%,#bbdef2);
	--grad-diag: linear-gradient(45deg,#d1aad7,#c88bc4 25.17%,#7b8fdd 50%,#86bff2 73.09%,#bbdef2);
	--transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
	--slate: #a1a1a6;
	--glass-black: rgb(23 23 23/1);
	--grad2: linear-gradient(90deg, var(--purple), var(--magenta));

}

body {
	font-family: Arial, Helvetica, sans-serif !important;
	background: black;
}

.partners {
	width:100%;
}

.regwrapper {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
}

.sec1 {
	margin: auto;
	padding: 10px;
	padding-top: 60px;
	padding-bottom: 60px;
	background-color: rgb(23,23,23);
}

.sec2 {
	margin: 0 auto;
	padding: 10px;
	padding-top: 60px;
	padding-bottom: 60px;
	background-color: black;
}

.italic {
	font-style: italic;
}

.thinfont {
	font-weight: 100 !important;
}


.gradtext {
	background: linear-gradient(90.13deg,#d1aad7 .11%,#c88bc4 25.06%,#7b8fdd 50%,#86bff2 74.8%,#bbdef2 99.76%);
	-webkit-background-clip: text;
	background-clip: text;
    -webkit-text-fill-color: transparent;
	display: inline;
}

.centertxt {
	text-align: center;
}


.slate {
	color: #a1a1a6 !important;
}
/** 
 * 	FONT SIZES
 ====================================================================*==================================*
 */

.headingLarge {
	color: #f5f5f7;
	font-size: 10rem;
    line-height: 1.05;
    font-weight: 600;
    letter-spacing: -.015em;
	font-family: Arial, Helvetica, sans-serif;

	/* font-family:  */
}

.headingSmall {
	font-weight: 500;
    font-size: 3.5rem;
    line-height: 105%;
	letter-spacing: -1px;
	font-family: Helvetica,Arial,sans-serif;
	color: white;
}

.subheadingLarger {
	font-size: 5rem;
	color: white;
	font-family: Aeonik,-apple-system,Arial,sans-serif;
	line-height: 1.14286;
    font-weight: 400;
    letter-spacing: .007em;
	color: #f5f5f7;
}

.subheadingLarge {
	font-size: 3rem;
    line-height: 1.25;
	color: white;
	font-family: Aeonik,-apple-system,Arial,sans-serif;
	/* font-weight: inherit; inherit font-weight from h1 */
}
.subheadingEx2 {
	font-weight: 700;
    font-size: 2.188rem;
    line-height: 105%;
	letter-spacing: -1px;
	font-family: Helvetica,Arial,sans-serif;
}


.subheading1 {
	font-size: 1.875rem;
    line-height: 1.5;
	color: white;
	font-family: Aeonik,-apple-system,Arial,sans-serif;
}

.subheading {
	/* font-size: 1.75rem; */
	font-size: 1.25rem;
    line-height: 1.14286;
    font-weight: 600;
    letter-spacing: .007em;
	color: #f5f5f7;
}

.subheading2 {
	line-height: 1.625;
	font-size: 1.25rem;
	color: rgb(212 212 212);
}
.subheading2W {
	line-height: 1.625;
	font-size: 1.25rem;
	color: white;
}

.subheadingEx1 {
	font-weight: 700;
    font-size: 1.563rem;
	line-height: 1.3em;
	letter-spacing: -.5px;
	font-family: Helvetica,Arial,sans-serif;
	color: white;
}

.subheading4 {
	font-size: 1.5rem;
    line-height: 1.65;
	color: rgb(255 255 255);
}
.subheading3 {
	font-size: 1.125rem;
    line-height: 1.65;
	color: rgb(255 255 255);
}

@media (max-width: 600px) {

	.headingLarge {
		/* font-size: 1.875rem; */
		font-size: clamp(16vw, 16vw, 10rem);
	}
	
	.headingSmall {
		font-size: 2rem;
	}
	
	.subheadingLarger {
		font-size: 3rem;
	}
	
	.subheadingLarge {
		/* own */
		font-size: 1.875rem;
	}
	.subheadingEx2 {
	
	}
	
	
	.subheading1 {
		font-size: 1.125rem;
	}
	
	.subheading {
		font-size: 1rem;
		
	}
	
	.subheading2 {
		font-size: 1rem;
	}
	.subheading2W {
		
	}
	.subheadingEx1 {
		
	}
	
	.subheading4 {
		font-size: 1.125rem;
	}
	.subheading3 {
		font-size: 1rem;
	}
	.headinggrad {
		font-size: .875rem !important;
	}

}

/** 
 
 ====================================================================*==================================*
 */

.textgray {
	color: rgb(163 163 163) !important;
}

.headinggrad {
	background: linear-gradient(90.13deg,#d1aad7 .11%,#c88bc4 25.06%,#7b8fdd 50%,#86bff2 74.8%,#bbdef2 99.76%);
	-webkit-background-clip: text;;
    -webkit-text-fill-color: transparent;
	display: inline-block;
	letter-spacing: .2rem;
    text-transform: uppercase;
	font-size: 1rem;
	color: rgb(255, 255, 255);
	font-family: Inter,system-ui,-apple-system,Arial,sans-serif;
	-webkit-text-size-adjust: 100%;
}

.tag {
	background-color: rgb(56, 55, 55);
	padding: 5px;
	padding-left: 10px;
	padding-right: 10px;
	margin-top: 10px;
	font-size: 1rem;
	font-weight: 600;
	color: var(--slate);
	font-style: monospace;
	text-transform: uppercase;
	display: inline-block;
	margin-right: 10px;
	opacity: 1;
}

.color1 {
	color: var(--purple) !important;
}

.color2 {
	color: var(--magenta) !important;
}

.sec2inneractionwrapper {
	max-width: 750px;
	margin: 0 auto;
	background-color: var(--frost-black);
	padding: 50px;
	border-radius: 1.5rem;

}
.sec1inneractionwrapper {
	max-width: 750px;
	margin: 0 auto;
	background-color: black;
	padding: 50px;
	border-radius: 1.5rem;

}

.leftrightmargin {
	margin-bottom: 75px;
}
.nopad {
	padding: 0;
}

.padright {
	padding-right: 50px;
}
.padleft {
	padding-left: 50px;
}
.centervert {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.gradborder {
	border-width: 2px;
  	border-style: solid;
  	border-image: var(--grad-diag) 1 1 1 1;
}
.maxsizeimgwrapper {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.maxsizeimg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
	margin: 10px;
	display: block;
}

.blackLink {
	text-decoration: underline;
	font-weight: bold;
	color: var(--magenta);
}
.blackLink:hover {
	color: var(--magenta);
}